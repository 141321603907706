<template>
  <div v-if="isLoading">
    <SkeletonText class="mt-4" v-if="false" />
    <SkeletonResult :type="'list'" class="mx-2"></SkeletonResult>
  </div>
  <div class="pb-2 px-1" v-else>
    <div
      class="mb-3"
      v-for="collection in getCollections"
      :key="collection.id"
      v-show="collection.products.length > 0 && collection.title !== 'Quiz'"
    >
      <div class="title py-2">Elijamos tu {{ collection.title }}</div>
      <div class="row">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 "
          v-for="product in collection.products"
          :key="product.id"
        >
          <div
            class="d-flex flex-column h-100 flex-grow-1  mx-1 pb-1 px-1 productContainer"
            style="cursor: pointer; "
            @click="
              addSelectProduct(
                parseInt(product.external_id),
                collection.external_id
              )
            "
          >
            <div
              class="SelectedProductStyle"
              v-if="IsProductSelected(parseInt(product.external_id))"
            >
              <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
            </div>
            <div class="mx-auto ImgContainer">
              <LazyImage :src="product.image" class="Img-Fluid w-100 " />
            </div>

            <div class="d-flex flex-column h-100">
               <div class="product-title text-decoration-underline flex-grow-1">
              <p class="p-0 m-0 py-2">{{ product.title }}</p>
            </div>
            <div class="product-price">
              <p class="p-0 m-0">&euro; {{ product.price }} EUR</p>
            </div>
            </div>
           
          </div>
        </div>
       
      </div>
    </div>
    <div
      class="d-flex justify-content-end flex-column flex-md-row mt-2 pb-2 pb-md-0"
    >
      <b-button
        pill
        class="dont-like-btn px-5 mx-2"
        style=""
        @click="jumpToRespectivePage"
        >No me gusta ninguno de estos</b-button
      >
      <!-- <b-button pill class="product-btn mt-3 mt-md-0" v-if="isBothCounterEqual"
        @click="addToWishList">Go to watch list</b-button
      > -->

      <b-button
        pill
        class="product-btn mt-3 mt-md-0"
        :disabled="!selectedProducts.length"
        @click="addToWishList"
      >
        <span v-if="!addProductLoading">{{
          isBothCounterEqual ? "Ir a mi wishlist" : "Siguiente"
        }}</span>
        <div v-else class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SkeletonText from "../components/Skeletons/ResultPage/Text.vue";
import SkeletonResult from "../components/Skeletons/ResultPage/ResultPage.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: { SkeletonText, SkeletonResult },
  props: {
    collections: Array,
    apiToken: String,
  },
  data() {
    return {
      isLoading: false,
      addProductLoading: false,
      selectedProducts: [],
      mapPages: {
        dormitorio: "BR.1",
        comedor: "DR.1",
        salón: "LR.1",
        oficina: "Office",
      },
      CollectionId: {
        dormitorio: 451410264361,
        comedor: 451518300457,
        salón: 451517972777,
        oficina: 451518169385,
      },
    };
  },

  methods: {
    IsProductSelected(id) {
      const index = this.selectedProducts.findIndex(
        (obj) => obj.product_id == id
      );

      return index > -1 ? true : false;
    },

    addSelectProduct(productId, collectionId) {
      const index = this.selectedProducts.findIndex(
        (obj) => obj.product_id == productId
      );
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      } else {
        let obj = {
          product_id: productId,
          collection_id: collectionId,
          main_collection_id:this.CollectionId[this.getCurrentCounterKey]
        };
        this.selectedProducts.push(obj);
      }
    },

    ...mapActions(["updateTrackCounter"]),
    ...mapMutations(["jumpToPreviewPage", "filterSelectedQuestionAnswer"]),
    findFirstDefaultCounter(obj) {
      for (const key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key].counter == 0
        ) {
          return key;
        }
      }

      return null;
    },

    filterOptionsAnswer() {
      let allSelectedOptions = [...this.getSelectedOptions.questionAnswer];
      let idToDelete = this?.getEnv.VUE_APP_FURNITURE_CATEGORY_QUESTION??[]
      // let idToDelete = [
      //   22460, 22610, 22611, 22906, 22907, 22615, 22616, 22617, 22946, 22947,
      //   22619, 22618, 22905,
      // ];

      let filteredArr = allSelectedOptions.filter(
        (obj) => !idToDelete.some((data) => data.id == obj.questionId)
      );
      return filteredArr;
    },
    async jumpToRespectivePage() {
      let filteredAnswers = this.filterOptionsAnswer();
      await this.filterSelectedQuestionAnswer(filteredAnswers);

      let ogCounter =
        this.getFurnitureClientCounterData()[this.getCurrentCounterKey].counter;
      let trackCounter =
        this.getTrackIkeaClientCounter[this.getCurrentCounterKey].counter;

      if (ogCounter > trackCounter) {
        const JumpToPreviewPageIndex = this.getTotalPreviewPages.findIndex(
          (x) => x.name == this.mapPages[this.getCurrentCounterKey]
        );

        await this.updateTrackCounter(this.getCurrentCounterKey);
        this.jumpToPreviewPage(JumpToPreviewPageIndex);
      } else {
        let getKey = this.findFirstDefaultCounter(
          this.getTrackIkeaClientCounter
        );

        if (getKey) {
          const JumpToPreviewPageIndex = this.getTotalPreviewPages.findIndex(
            (x) => x.name == this.mapPages[getKey]
          );

          await this.updateTrackCounter(getKey);
          this.jumpToPreviewPage(JumpToPreviewPageIndex);
        } else {
          let URL = `https://amuebla.bechester.com/pages/wishlist`;
            //  window.location.href =URL;
          window.open(URL, "_blank");
        }
      }
    },

    async addToWishList() {
      this.addProductLoading = true;
      try {
        let data = {
          email: this.getEmail,
          selected_collection_products: this.selectedProducts,
        };
        const response = await axios.post(
          `/api/addSelectedCollectionProduct`,
          data
        );
        if (response.status == 200) {
          this.jumpToRespectivePage();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.addProductLoading = false;
      }
    },
  },
  computed: {
    getCollections() {
      return this.collections;
    },
    ...mapGetters([
      "getTotalPreviewPages",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getCurrentActiveCounterKey",
      "getSelectedOptions",
      "getPreviewSettings",
      "getSavedLeadID",
      "getEnvConst"
    ]),
    getEnv(){
      // ...mapGetters(["getEnvConst"]),
      return this.getEnvConst;
    },
    getEmail() {
      return this.getSavedLeadID.email;
    },

    getCurrentCounterKey() {
      return this.getCurrentActiveCounterKey;
    },

    isBothCounterEqual() {
      return (
        JSON.stringify(this.getFurnitureClientCounterData()) ===
        JSON.stringify(this.getTrackIkeaClientCounter)
      );
    },
  },
  mounted() {
    this.selectedProducts = [];
  },
};
</script>

<style>
.dont-like-btn:active,
.product-btn:active {
  background-color: #4fa4a2 !important;
  border: none;
}
.dont-like-btn {
  border-radius: 50px;
  border: 1px solid rgba(42, 175, 175, 0.3);
  background: transparent;
  color: black;
}
.dont-like-btn:hover {
  background: #4fa4a2;
  color: white;
  border: none;
}
.product-btn {
  min-width: 120px;
  min-height: 45px;
  background: #4fa4a2;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.product-btn:hover {
  background: #3a7978;
}
.title {
  color: var(--black, #313131);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.text-decoration-underline {
  text-decoration: underline;
}
</style>

<style scoped>
.productContainer {
  border-radius: 4px;
  border: 1px solid rgba(42, 175, 175, 0.3);
  position: relative;
}

.productContainer .SelectedProductStyle {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #4FA4A2;
  font-size: 12px;
}


.productContainer .ImgContainer img{
 /* width:100%; */
  max-width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 2/3;
 /* object-fit: cover; */
  
}
.productContainer .ImgContainer {
 
  width: 50%;
  
}

@media (max-width: 991px) {
 .productContainer .ImgContainer {
  width:100%;
  
}
}
@media (max-width: 767px) {
  .productContainer .ImgContainer{
  max-width:172px;
  
}
}
</style>
